/* eslint-disable space-before-function-paren */
import React, { useState } from 'react'
import './header.scss'

// import cima from '../../images/icon/home/seta-para-baixo.svg'
// import baixo from '../../images/icon/seta-para-baixo.svg'

// assets
// import lupa from '../../images/lupa.svg'
import logo from '../../images/icon/logo2.svg'
import menu from '../../images/icon/home/icone menu.svg'
import fechar from '../../images/icon/home/fechar.svg'
import { useIntl, changeLocale, Link } from 'gatsby-plugin-react-intl'

// components
import ModalSearch from '../SearchModal/search'

export default function headerMobile({ location }) {
  const [active, setActive] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)
  const [opened] = useState(true)
  // eslint-disable-next-line no-unused-vars

  const handleMobileMenu = () => {
    setOpenMenu(!openMenu)
    if (!openMenu) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden'
      document.getElementsByTagName('html')[0].style.overflow = 'hidden'
    } else {
      document.getElementsByTagName('html')[0].style.overflow = 'auto'
      document.getElementsByTagName('body')[0].style.overflow = 'auto'
    }
  }

  React.useEffect(() => {
    return () => {
      document.getElementsByTagName('body')[0].style.overflow = 'auto'
    }
  }, [])

  const intl = useIntl()

  return (
    <>
      <header id="headerMobile" className="position-relative">
        <nav className="height2 bg-dia-red d-flex align-items-center justify-content-between px-4">
          <div className="logoMobile" ><Link aria-label="logohome" to="/"><img src={logo} alt="logo" /></Link></div>
          <div className="pl-0">
            {/* <button aria-label="LupaButton" onClick={() => setActive(true)} className="lupaButton"><img src={lupa} alt="lupa"/> </button> */}
            <div className='mt-2'>
              <span className="cursor-pointer" onClick={() => changeLocale('pt')}>PT / </span>
              <span className="cursor-pointer" onClick={() => changeLocale('en')}>EN / </span>
              <span className="cursor-pointer" onClick={() => changeLocale('es')}>ES</span>
            </div>
          </div>
          <div onClick={() => handleMobileMenu()}>
            <img src={openMenu ? fechar : menu} alt="Button" />
          </div>
        </nav>

        {
          openMenu && (
            <div className="heightMenu">
              <div className="">
                <div className="">
                  <div className="menuWhite">
                    <Link to="/nossa-essencia/" onClick={() => handleMobileMenu()}>
                      <div className="text-center d-block font-size-1 text-lwart-darkblue font-Spinnaker navItem pt-3">
                        {intl.formatMessage({ id: 'components.navbar.link1' })}
                      </div>
                    </Link>
                    <div>
                      <Link className="text-center d-block font-size-1 text-lwart-darkblue font-Spinnaker navItem" to="/nossas-solucoes/" onClick={() => handleMobileMenu()}>
                        {intl.formatMessage({ id: 'components.navbar.link2' })}
                      </Link>
                      <div className={opened ? 'dropdown-nav-mobile' : 'd-none'}>
                        <div className="navbarBoxMobile my-1">
                          <Link to="/coleta-de-oleo-lubrificante-usado/" onClick={() => handleMobileMenu()}>
                            <div id="prod-lwart-link" className='text-white font-size-09 nav-item bg-lwart-lightblue2' >
                              {intl.formatMessage({ id: 'components.navbar.sublinks.link1' })}
                            </div>
                          </Link>
                          <Link to="/oleos-de-alta-performance/" onClick={() => handleMobileMenu()}>
                            <div id="prod-lwart-link" className='nav-item bg-lwart-darkblue mt-1 text-white font-size-09' >
                              {intl.formatMessage({ id: 'components.navbar.sublinks.link2' })}
                            </div>
                          </Link>
                          <Link to="/g2/" onClick={() => handleMobileMenu()} >
                            <div id="prod-lwart-link" className='text-dark font-size-09 nav-item'>
                              {intl.formatMessage({ id: 'components.navbar.sublinks.link3' })}
                            </div>
                          </Link>
                          <div className='bg-lwart-blue hr'></div>
                          <Link to="/lwoma/" onClick={() => handleMobileMenu()} >
                            <div id="prod-lwart-link" className='text-dark font-size-09 nav-item'>
                              {intl.formatMessage({ id: 'components.navbar.sublinks.link4' })}
                            </div>
                          </Link>
                          <div className='bg-lwart-blue hr'></div>
                          <Link to="/lw-volt/" className="pb-2" onClick={() => handleMobileMenu()} >
                            <div id="prod-lwart-link" className='text-dark font-size-09 nav-item'>
                              {intl.formatMessage({ id: 'components.navbar.sublinks.link5' })}
                            </div>
                          </Link>
                          <div className='bg-lwart-blue hr'></div>
                          <Link to="/grau-alimenticio/" className="pb-2" onClick={() => handleMobileMenu()} >
                            <div id="prod-lwart-link" className='text-dark font-size-09 nav-item'>
                              {intl.formatMessage({ id: 'components.navbar.sublinks.link13' })}
                            </div>
                          </Link>
                          <Link to="/gestao-de-residuos/" onClick={() => handleMobileMenu()} >
                            <div id="prod-lwart-link" className='text-white font-size-09 nav-item bg-lwart-green'>
                              {intl.formatMessage({ id: 'components.navbar.sublinks.link6' })}
                            </div>
                          </Link>
                          <Link to="/gestao-total-de-residuos/" onClick={() => handleMobileMenu()}>
                            <div id="prod-lwart-link" className='nav-item text-dark font-size-09 '>
                              {intl.formatMessage({ id: 'components.navbar.sublinks.link7' })}
                            </div>
                          </Link>
                          <div className='bg-lwart-blue hr'></div>
                          <Link to="/coleta-e-destinacao-de-residuos/" onClick={() => handleMobileMenu()}>
                            <div id="prod-lwart-link" className='nav-item text-dark font-size-09 ' >
                              {intl.formatMessage({ id: 'components.navbar.sublinks.link8' })}
                            </div>
                          </Link>
                          <div className='bg-lwart-blue hr'></div>
                          <Link to="/assessoria-ambiental/" onClick={() => handleMobileMenu()}>
                            <div id="prod-lwart-link" className='nav-item text-dark font-size-09 ' >
                              {intl.formatMessage({ id: 'components.navbar.sublinks.link9' })}
                            </div>
                          </Link>
                          <div className='bg-lwart-blue hr'></div>
                          <Link to="/transbordo-de-residuos/" onClick={() => handleMobileMenu()}>
                            <div id="prod-lwart-link" className='nav-item text-dark font-size-09 ' >
                              {intl.formatMessage({ id: 'components.navbar.sublinks.link10' })}
                            </div>
                          </Link>
                          <div className='bg-lwart-blue hr'></div>
                          <Link to="/scrap-produtivo-e-descaracterizacao/" onClick={() => handleMobileMenu()}>
                            <div id="prod-lwart-link" className='nav-item text-dark font-size-09 ' >
                              {intl.formatMessage({ id: 'components.navbar.sublinks.link11' })}
                            </div>
                          </Link>
                          <div className='bg-lwart-blue hr'></div>
                          <a href='https://sga.lwart.com.br/' target="_blank" onClick={() => handleMobileMenu()} className="pb-2" rel="noreferrer">
                            <div id="prod-lwart-link" className='nav-item text-dark font-size-09 pb-lg-2 pb-2' >
                              {intl.formatMessage({ id: 'components.navbar.sublinks.link12' })}
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                    <Link to="/sustentabilidade/" onClick={() => handleMobileMenu()}>
                      <div className="text-center d-block font-size-1 text-lwart-darkblue font-Spinnaker navItem">
                        {intl.formatMessage({ id: 'components.navbar.link3' })}
                      </div>
                    </Link>
                    {intl.locale === 'pt' &&
                      <Link to="/blog/" onClick={() => handleMobileMenu()}>
                        <div className="text-center d-block font-size-1 text-lwart-darkblue font-Spinnaker navItem" >
                          {intl.formatMessage({ id: 'components.navbar.link4' })}
                        </div>
                      </Link>
                    }
                    <Link to="/gente/" onClick={() => handleMobileMenu()}>
                      <div className="text-center d-block font-size-1 text-lwart-darkblue font-Spinnaker navItem" >
                        {intl.formatMessage({ id: 'components.navbar.link5' })}
                      </div>
                    </Link>
                    {
                      intl.locale === 'pt'
                        ? <div>
                          <Link className="text-center d-block font-size-1 text-lwart-darkblue font-Spinnaker navItem" to="/informacoes-financeiras/" onClick={() => handleMobileMenu()}>
                            RI
                          </Link>
                          <div className={opened ? 'dropdown-nav-mobile' : 'd-none'}>
                            <div className="navbarBoxMobile my-1">
                              <Link to="/informacoes-financeiras/" onClick={() => handleMobileMenu()} >
                                <div id="prod-lwart-link" className='text-dark font-size-09 nav-item'>
                                  Informações Financeiras
                                </div>
                              </Link>
                              <div className='bg-lwart-blue hr'></div>
                              <Link to="/fale-com-ri/" className="pb-2" onClick={() => handleMobileMenu()} >
                                <div id="prod-lwart-link" className='text-dark font-size-09 nav-item'>
                                  Fale com RI
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                        : ''
                    }
                    {
                      intl.locale === 'pt'
                        ? <Link to="/projeto-h/" onClick={() => handleMobileMenu()}>
                          <div className="text-center d-block font-size-1 text-lwart-darkblue font-Spinnaker navItem" >
                            H+
                          </div>
                        </Link>
                        : ''
                    }
                    <Link to="/fale-com-a-gente/" onClick={() => handleMobileMenu()}>
                      <div className="text-center d-block font-size-1 text-lwart-darkblue font-Spinnaker navItem" >
                        {intl.formatMessage({ id: 'components.navbar.link6' })}
                      </div>
                    </Link>
                    <div className='customSpace'>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </header>
      <ModalSearch active={active} setActive={setActive} location={location} />
    </>
  )
}
