/* eslint-disable space-before-function-paren */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { globalHistory } from '@reach/router'
import Logo from '../../images/icon/logo2.svg'
import './header.scss'
import { useIntl, changeLocale, Link } from 'gatsby-plugin-react-intl'

export default function Header({ location }) {
  const [opened, isOpenned] = useState(false)
  const [open, isOpen] = useState(false)
  const [activeOption1, setActiveOption1] = useState(false)
  const [activeOption2, setActiveOption2] = useState(false)
  const [activeOption3, setActiveOption3] = useState(false)
  const [activeOption4, setActiveOption4] = useState(false)
  const [activeOption5, setActiveOption5] = useState(false)
  const [activeOption6, setActiveOption6] = useState(false)
  const [activeOption61, setActiveOption61] = useState(false)
  const [activeOption62, setActiveOption62] = useState(false)
  const [activeOption63, setActiveOption63] = useState(false)
  const [searchItems, setSearchItems] = useState([])
  const path = globalHistory.location.pathname

  const handleSearch = (e) => {
    const url = e.target.value.replace(' ', '+')

    fetch(`${process.env.SEARCH_API}?q=${url}&return=resourcename%2c_score`)
      .then(res => res.json())
      .then(res => setSearchItems(res.hits.hit))
  }
  const intl = useIntl()
  useEffect(() => {
    console.log(intl.locale)
  }, [])
  return (
    <>
      <header className="bg-light" id='topo'>
        <nav className="navbar navbar-expand-lg navbar-light container d-lg-flex d-none pt-3">
          <div>
            <Link className="navbar-brand-0" to="/">
              <img src={Logo} className="logoHeader" alt="Imagem Logo Lwart" width='255' height='82' /></Link>
            {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" ></span>
            </button> */}
          </div>
          <div className="collapse navbar-collapse align-self-end" id="navbarNavDropdown">
            <ul className="navbar-nav justify-content-between mb-2">
              <li>
                <Link to="/nossa-essencia/" id="prod-lwart-link" className={path === '/nossa-essencia/' && activeOption1 === false ? 'nav-link text-lwart-blue2 font-size-09 ml-2' : 'nav-link text-black ml-2 font-size-09'}>{intl.formatMessage({ id: 'components.navbar.link1' })}</Link>
              </li>
              <li className="nav-item ml-2 position-relative">
                <Link to="/nossas-solucoes/" id="prod-lwart-link" className={path === '/nossas-solucoes/' && activeOption6 === false ? 'nav-link text-lwart-blue2 font-size-09 ml-2' : 'nav-link text-black ml-2 font-size-09'}
                  onMouseEnter={() => { isOpenned(true) }}
                  onMouseLeave={() => { isOpenned(false) }}
                >{intl.formatMessage({ id: 'components.navbar.link2' })}</Link>
                <div className={opened ? 'dropdown-nav' : 'd-none'} onMouseEnter={() => { isOpenned(true) }} onMouseLeave={() => { isOpenned(false) }}>
                  <div className="navbarBox pt-1">
                    <Link to="/coleta-de-oleo-lubrificante-usado/" id="prod-lwart-link" className={path === '/coleta-de-oleo-lubrificante-usado/' && activeOption61 === false ? 'font-weight-bold text-white ' : 'text-white '} >
                      <div className="nav-main-items nav-main-hover1 bg-lwart-lightblue2 font-size-09">{intl.formatMessage({ id: 'components.navbar.sublinks.link1' })}</div>
                    </Link>
                    <Link to="/oleos-de-alta-performance/" id="prod-lwart-link" className={path === '/oleos-de-alta-performance/' && activeOption62 === false ? 'font-weight-bold text-white ' : 'text-white '}>
                      <div className="nav-main-items nav-main-hover2 bg-lwart-darkblue mt-1 font-size-09">{intl.formatMessage({ id: 'components.navbar.sublinks.link2' })}</div>
                    </Link>
                    <Link to="/g2/" id="prod-lwart-link" className=' ' >
                      <div className="nav-item text-dark font-size-09">
                        {intl.formatMessage({ id: 'components.navbar.sublinks.link3' })}
                      </div>
                      <div className='bg-lwart-blue hr'></div>
                    </Link>
                    <Link to="/lwoma/" id="prod-lwart-link" >
                      <div className="nav-item text-dark font-size-09">
                        {intl.formatMessage({ id: 'components.navbar.sublinks.link4' })}
                      </div>
                      <div className='bg-lwart-blue hr'></div>
                    </Link>
                    <Link to="/lw-volt/" id="prod-lwart-link" >
                      <div className="nav-item text-dark font-size-09">
                        {intl.formatMessage({ id: 'components.navbar.sublinks.link5' })}
                      </div>
                      <div className='bg-lwart-blue hr'></div>
                    </Link>
                    <Link to="/grau-alimenticio/" id="prod-lwart-link" >
                      <div className="nav-item text-dark font-size-09">
                        {intl.formatMessage({ id: 'components.navbar.sublinks.link13' })}
                      </div>
                    </Link>
                    <Link to="/gestao-de-residuos/" id="prod-lwart-link" className={path === '/gestao-de-residuos/' && activeOption63 === false ? 'font-weight-bold text-white' : 'text-white'}>
                      <div className="nav-main-items nav-main-hover3 bg-lwart-green font-size-09 "> {intl.formatMessage({ id: 'components.navbar.sublinks.link6' })} </div>
                    </Link>
                    <Link to="/gestao-total-de-residuos/" id="prod-lwart-link" >
                      <div className="nav-item text-dark font-size-09">
                        {intl.formatMessage({ id: 'components.navbar.sublinks.link7' })}
                      </div>
                      <div className='bg-lwart-blue hr'></div>
                    </Link>
                    <Link to="/coleta-e-destinacao-de-residuos/" id="prod-lwart-link" >
                      <div className="nav-item text-dark font-size-09">
                        {intl.formatMessage({ id: 'components.navbar.sublinks.link8' })}
                      </div>
                      <div className='bg-lwart-blue hr'></div>
                    </Link>
                    <Link to="/assessoria-ambiental/" id="prod-lwart-link" >
                      <div className="nav-item text-dark font-size-09">
                        {intl.formatMessage({ id: 'components.navbar.sublinks.link9' })}
                      </div>
                      <div className='bg-lwart-blue hr'></div>
                    </Link>
                    <Link to="/transbordo-de-residuos/" id="prod-lwart-link" >
                      <div className="nav-item text-dark font-size-09">
                        {intl.formatMessage({ id: 'components.navbar.sublinks.link10' })}
                      </div>
                      <div className='bg-lwart-blue hr'></div>
                    </Link>
                    <Link to="/scrap-produtivo-e-descaracterizacao/" id="prod-lwart-link" >
                      <div className="nav-item text-dark font-size-09">
                        {intl.formatMessage({ id: 'components.navbar.sublinks.link11' })}
                      </div>
                      <div className='bg-lwart-blue hr'></div>
                    </Link>
                    <a href="https://sga.lwart.com.br/" target="_blank" id="prod-lwart-link" rel="noreferrer" >
                      <div className="nav-item text-dark font-size-09">
                        {intl.formatMessage({ id: 'components.navbar.sublinks.link12' })}
                      </div>
                    </a>
                  </div>
                </div>
              </li>
              <li>
                <Link to="/sustentabilidade/" id="prod-lwart-link" className={path === '/sustentabilidade/' && activeOption2 === false ? 'nav-link text-lwart-blue2 font-size-09 ml-2' : 'nav-link text-black ml-2 font-size-09'}>{intl.formatMessage({ id: 'components.navbar.link3' })}</Link>
              </li>
              {intl.locale === 'pt' &&
                <li>
                  <Link to="/blog/" id="prod-lwart-link" className={path === '/blog/' && activeOption3 === false ? 'nav-link text-lwart-blue2 font-size-09 ml-2' : 'nav-link text-black ml-2 font-size-09'} >{intl.formatMessage({ id: 'components.navbar.link4' })}</Link>
                </li>
              }
              <li>
                <Link to="/gente/" id="prod-lwart-link" className={path === '/gente/' && activeOption4 === false ? 'nav-link text-lwart-blue2 font-size-09 ml-2' : 'nav-link text-black ml-2 font-size-09'}>{intl.formatMessage({ id: 'components.navbar.link5' })}</Link>
              </li>
              {
                intl.locale === 'pt'
                  ? <li className="nav-item ml-2 position-relative">
                    <Link to="/informacoes-financeiras/" id="prod-lwart-link" className={path === '/informacoes-financeiras/' && activeOption6 === false ? 'nav-link text-lwart-blue2 font-size-09 ml-2' : 'nav-link text-black ml-2 font-size-09'}
                      onMouseEnter={() => { isOpen(true) }}
                      onMouseLeave={() => { isOpen(false) }}
                    >RI</Link>
                    <div className={open ? 'dropdown-nav' : 'd-none'} onMouseEnter={() => { isOpen(true) }} onMouseLeave={() => { isOpen(false) }}>
                      <div className="navbarBox pt-1">
                        <Link to="/informacoes-financeiras/" id="prod-lwart-link" >
                          <div className="nav-item text-dark font-size-09">
                            Informações Financeiras
                          </div>
                          <div className='bg-lwart-blue hr'></div>
                        </Link>
                        <Link to="/fale-com-ri/" id="prod-lwart-link" >
                          <div className="nav-item text-dark font-size-09">
                            Fale com RI
                          </div>
                        </Link>
                      </div>
                    </div>
                  </li>
                  : ''
              }
              {
                intl.locale === 'pt'
                  ? <li><Link to="/projeto-h/" id="prod-lwart-link" className={path === '/projeto-h/' && activeOption4 === false ? 'nav-link text-lwart-blue2 font-size-09 ml-2' : 'nav-link text-black ml-2 font-size-09'}>H+</Link></li>
                  : ''
              }
              <li>
                <Link to="/fale-com-a-gente/" id="prod-lwart-link" className={path === '/fale-com-a-gente/' && activeOption5 === false ? 'nav-link text-lwart-blue2 font-size-09 ml-2' : 'nav-link text-black ml-2 font-size-09'}>{intl.formatMessage({ id: 'components.navbar.link6' })}</Link>
              </li>
            </ul>
          </div>
          <div className='mt-4'>
            <span className="cursor-pointer font-size-09" onClick={() => changeLocale('pt')}>PT / </span>
            <span className="cursor-pointer font-size-09" onClick={() => changeLocale('en')}>EN / </span>
            <span className="cursor-pointer font-size-09" onClick={() => changeLocale('es')}>ES</span>
          </div>
        </nav>
        <div className="gradient-blue-to-green"></div>
      </header>
    </>
  )
}
